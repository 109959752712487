/*The purpose of this file is to pull in all global variables so other css modules can simply import this file */
@import './Typography.css';
@import './ColorScheme.css';
@import './utilities.css';
@import './Shadows.css';
@import './Layout.css';
@import './PageSharedStyles.css';
@import './FormsSharedStyles.css';

/* Overwrite default button styling */
button,
input[type='submit'],
input[type='reset'] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 0;
}

/* Outline for accessibility */
button:focus,
input[type='submit']:focus,
input[type='reset']:focus {
    outline: 1px dotted #333333;
}

/* 1. Use a more-intuitive box-sizing model. */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* 2. Remove default margin */
* {
    margin: 0;
}

/*
    3. Allow percentage-based heights in the application
  */
html,
body {
    height: 100%;
}

/*
    Typographic tweaks!
    4. Add accessible line-height
    5. Improve text rendering
  */
body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;

    /* default background color */
    background-color: var(--token-color-background-secondary);
}

/*
    6. Improve media defaults
  */
img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

/*
    7. Remove built-in form typography styles
  */
input,
button,
textarea,
select {
    font: inherit;
}

/*
    8. Avoid text overflows
  */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

/*
    9. Create a root stacking context
  */
#root {
    isolation: isolate;
    /* Default all text color to standard */
    color: var(--token-color-text-standard);
}
