.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    flex-shrink: 0;
    /* The background color here is used as a default */
    background-color: var(--token-background-primary);
    position: relative;
    color: var(--token-color-category-icon);
}

.inactive {
    filter: grayscale(100%);
}

.revolvingIcon {
    top: 3px;
    right: 3px;
    position: absolute;
    font-size: 14px;
    color: #f9f9f9;
    height: 14px;
    transform: rotate(0deg);
    animation-name: rotating;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes rotating {
    0% {
        transform: rotate(0deg);
    }
    3% {
        transform: rotate(-35deg);
    }
    11% {
        transform: rotate(735deg);
    }
    14%,
    100% {
        transform: rotate(720deg);
    }
}
