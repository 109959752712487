.placeholderRecurringContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--token-color-border-secondary);
    width: 100%;
}

.listContainer {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}
