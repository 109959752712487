.loader {
    height: 25px;
    width: 25px;
    border: 3px solid rgba(255, 255, 255, 0.6);
    border-top: 3px solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: auto;
}

.light {
    border: 3px solid rgba(255, 255, 255, 0.6);
    border-top: 3px solid #fff;
}

.red {
    border: 3px solid rgba(255, 0, 0, 0.6);
    border-top: 3px solid #f00;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
