.optionButton {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 16px;
    min-width: 120px;
    background-color: inherit;
    border: 1px solid var(--token-button-secondary-color);
    color: var(--token-button-secondary-hover-color);
}

.optionButton:hover {
    background-color: var(--theme-color-neutral-700);
    border: 1px solid var(--token-button-secondary-hover-color);
}
