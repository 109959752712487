.navContainer {
    display: flex;
    justify-content: space-around;
    padding: 8px 16px 24px; /* Should have the same side padding as --page-side-padding */
    background-color: var(--token-color-background-primary);
    border-top: 2px solid var(--token-color-border-tertiary);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    min-width: var(--breakpoint-min-supported-width);
    isolation: isolate;
    z-index: 1;
}

.outletSpacer {
    padding-top: 120px;
}

.menuItem {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-decoration: none;
}

.icon {
    --icon-size: 2.25rem;
    width: var(--icon-size);
    height: var(--icon-size);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
}

.selectionBackground {
    position: absolute;
    width: 50%;
    height: 50%;
    background-color: var(--token-button-primary-color);
    border-radius: 50%;
    transition-duration: 0.4s;
    z-index: -1;
}

.navLabel {
    flex-basis: 100%;
    font-size: 10px;
    color: var(--token-color-text-subdued);
    text-align: center;
    text-decoration: none;
    text-wrap: wrap;
}

.outletContainer {
    --page-side-padding: 16px;

    padding-top: 24px;
}
