.heading {
    font-size: var(--font-body-large);
    font-weight: 800;
    padding-bottom: 1rem;
}

.description {
    font-size: var(--font-body-medium);
    padding-bottom: 1.5rem;
}

.finalWarning {
    font-size: var(--font-body-medium);
    color: var(--token-color-semantic-danger);
    font-style: italic;
}
