:root {
    --form-space-between-items: 28px;

    /* Form labels */
    --form-label-font-weight: var(--fw-semi-bold);
    --form-label-color: var(--theme-light-text-color);
    --form-label-font-size: var(--font-body-small);
    --form-padding-below-label: var(0.5rem);

    /* Text inputs */
    --form-input-font-size: var(--font-body-medium);

    /* Check box input */
    --form-checkbox-size: 18px;
    --form-check-box-label-font-size: var(--font-body-small);
    --form-check-box-label-font-weight: var(--font-weight-regular);
}
