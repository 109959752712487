.accountRow {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e0e0e0;
    align-items: center;
}

.totalAmount {
    margin-left: auto;
}
