.spendTotal {
    display: flex;
    column-gap: 8px;
    flex-basis: 0;
    flex-grow: 1;
    align-items: center;
}

.icon {
    border-radius: 50%;
}

.spendCategory {
    font-size: var(--font-body-small);
    font-weight: var(--font-weight-medium);
}

.amountSpent {
    font-size: var(--font-body-small);
    font-weight: var(--font-weight-regular);
}
