.container {
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: space-between;
    max-width: 220px;
}

.timeFrameButton {
    font-size: var(--font-body-medium);
    font-weight: var(--font-weight-semi-bold);
}

.yearLabel {
    letter-spacing: 8px;
}

.arrowButton {
    font-size: 24px;
    min-width: 24px;
    transition: opacity 0.2s;
}

.disabledArrowButton {
    opacity: 0.5;
}
