.defaultButton {
    font-size: var(--font-body-medium);
    text-align: center;
    border-radius: 12px;
    font-weight: var(--fw-regular);
    color: #fff;
    transition-duration: 0.3s;
}

.disabled {
    background-color: var(--token-disable-button-background) !important;
}

/* Variants based on variant prop, so name of these classes matters */
.primary {
    background-color: var(--token-button-primary-color);
}

.primary:hover {
    background-color: var(--token-button-primary-hover-color);
}

.secondary {
    background-color: var(--token-button-secondary-color);
}

.secondary:hover {
    background-color: var(--token-button-secondary-hover-color);
}

.tertiary {
    background-color: var(--token-button-tertiary-color);
}

.tertiary:hover {
    background-color: var(--token-button-tertiary-hover-color);
}

.detail {
    background-color: var(--token-button-details-color);
}

.detail:hover {
    background-color: var(--token-button-details-color-hover);
}
